import logo from './logo.svg';
import './App.css';
import UploadToS3WithQR from './UploadToS3WithQR';

function App() {
  return (
    <div className="App" >
     <UploadToS3WithQR/>
    </div>
  );
}

export default App;
