import React, { useState, useRef, useEffect } from "react";
import AWS from "aws-sdk";
import {
  CloudUploadOutlined,
  PlayCircleOutlined,
  FileImageOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Image, Progress, Row, Typography } from "antd";
import img1 from "./images/pose.jpg";
import img2 from "./images/_DSC3947.JPG";
import img3 from "./images/_DSC3998.JPG";
import img4 from "./images/_DSC4065.JPG";
import img5 from "./images/_DSC4083.JPG";
import img6 from "./images/_DSC4103.JPG";
import img7 from "./images/_DSC4264.JPG";
import img8 from "./images/_DSC4264.JPG";
import img9 from "./images/_DSC4435.JPG";
import img10 from "./images/_DSC3577.JPG";
import img12 from "./images/_DSC3690.JPG";
import img13 from "./images/_DSC3743.JPG";
import img14 from "./images/_DSC3899.JPG";
import img15 from "./images/_DSC4110.JPG";
import img16 from "./images/_DSC4182.JPG";
import img17 from "./images/_DSC4214.JPG";
import img18 from "./images/_DSC4503.JPG";
import img19 from "./images/_DSC4536.JPG";

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
];

const UploadToS3WithQR = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [uploadProgressMap, setUploadProgressMap] = useState({});
  const [uploadComplete, setUploadComplete] = useState(false);

  const [currentImageIndex, setCurrentImageIndex] = useState(
    Math.floor(Math.random() * images.length)
  );
  const changeImage = () => {
    const randomNumber = Math.floor(Math.random() * images.length);
    setCurrentImageIndex(randomNumber);
  };
  const isMobileView = window.innerWidth < 768;

  useEffect(() => changeImage(), []);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newUploadProgressMap = {};
    for (const file of files) {
      newUploadProgressMap[file.name] = 0;
    }
    setUploadProgressMap(newUploadProgressMap);
    setSelectedFiles([...selectedFiles, ...files]);
  };
  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const removeFile = (indexToRemove) => {
    setSelectedFiles(
      selectedFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const uploadFilesToS3 = () => {
    if (selectedFiles.length === 0) {
      alert("Please select at least one file");
      return;
    }

    setUploading(true);
    let uploadedFilesCount = 0;

    const s3 = new AWS.S3({
      accessKeyId: "juyvfwh3cym4i65hjrdhc4i2grga",
      secretAccessKey: "jycmkuafprogshfs5qonldj7cegvbq6tanoah6wkoow6g4tz7elom",
      region: "ap-south-1",
      endpoint: "https://gateway.storjshare.io",
      s3ForcePathStyle: true,
      signatureVersion: "v4",
    });

    selectedFiles.forEach((file) => {
      const params = {
        Bucket: "wedding",
        Key: file.name,
        Body: file,
        ContentType: file.type,
      };

      s3.upload(params)
        .on("httpUploadProgress", function (progress) {
          const uploadedPercentage = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setUploadProgressMap((prevProgress) => ({
            ...prevProgress,
            [file.name]: uploadedPercentage,
          }));
        })
        .send((err, data) => {
          if (err) {
            console.error(err);
            alert(`Failed to upload ${file.name}`);
          } else {
            console.log(
              `File ${file.name} uploaded successfully:`,
              data.Location
            );
            uploadedFilesCount++;
            if (uploadedFilesCount === selectedFiles.length) {
              setUploadComplete(true);
              setSelectedFiles([]);
              setUploadProgressMap({});
              resetFileInput();
              setUploading(false);
            }
          }
        });
    });
  };

  const handleSelectImagesClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click event
    }
  };

  const getFilePreview = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (fileExtension.match(/(jpg|jpeg|png|gif)$/)) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          style={{ width: "32px", height: "32px", marginRight: "10px" }}
        />
      );
    } else if (fileExtension.match(/(mp4|mov|avi|wmv)$/)) {
      return (
        <PlayCircleOutlined
          style={{ fontSize: "32px", marginRight: "10px", color: "#AA1945" }}
        />
      );
    } else {
      return (
        <FileImageOutlined
          style={{ fontSize: "32px", marginRight: "10px", color: "#AA1945" }}
        />
      );
    }
  };

  return (
    <div
      style={{
        padding: "1rem",
      }}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Card
            bordered={false}
            style={{
              borderColor: "red",
              height: "100%",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              backgroundColor: "#e0a4b32e",
              backgroundImage: `url(${images[currentImageIndex]})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "42rem",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(to bottom, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0.1) 100%)",
              }}
            ></div>
            {uploadComplete ? (
              <div>
                <Typography.Title
                  level={3}
                  style={{
                    margin: 0,
                    color: "#AA1945",
                    padding: "1.5rem 5px",
                    textAlign: "center",
                  }}
                >
                  Thank You For Sharing Your Memories With Us!{" "}
                </Typography.Title>
                <img
                  src={images[currentImageIndex]}
                  alt={images[currentImageIndex]}
                  style={{
                    width: isMobileView ? "100%" : "50%",
                    height: "auto",
                    maxHeight: isMobileView ? "16rem" : "18rem",
                    objectFit: "cover",
                    padding: "2.5rem 5px",
                  }}
                />
                <Button
                  type="primary"
                  danger
                  onClick={() => setUploadComplete(false)}
                >
                  Share More
                </Button>
              </div>
            ) : (
              <div>
                <Typography.Title
                  level={2}
                  style={{ margin: 0, color: "#AA1945", padding: "1rem" }}
                >
                  Capture the Moments{" "}
                </Typography.Title>
                <div>
                  <Card
                    bordered={false}
                    style={{
                      borderColor: "red",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      backgroundColor: "#fff",
                      height: "10rem",
                    }}
                  >
                    {" "}
                    {uploading ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "5px",
                        }}
                      >
                        <Typography.Title
                          level={5}
                          style={{
                            margin: 0,
                            color: "#AA1945",
                            padding: "1rem",
                            textAlign: "center",
                          }}
                        >
                          Uploading
                        </Typography.Title>
                      </div>
                    ) : (
                      <CloudUploadOutlined
                        style={{ fontSize: "5rem", color: "#FB6090" }}
                      />
                    )}
                    <Col span={24}>
                      <input
                        type="file"
                        onChange={handleFileChange}
                        multiple
                        ref={fileInputRef}
                        style={{ display: "none" }} // Hide the input element
                      />
                      {selectedFiles.length === 0 && (
                        <Button
                          type="primary"
                          danger
                          onClick={handleSelectImagesClick}
                        >
                          Select Images
                        </Button>
                      )}
                      {selectedFiles.length > 0 && (
                        <Button
                          type="primary"
                          danger
                          onClick={uploadFilesToS3}
                          disabled={uploading}
                        >
                          Upload{" "}
                        </Button>
                      )}
                    </Col>
                  </Card>
                  <div
                    style={{
                      marginTop: "1rem",
                      // maxHeight: "200px",
                      overflowY: "auto",
                      height: "17rem",
                    }}
                  >
                    {selectedFiles.length > 0 ? (
                      selectedFiles.map((file, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "5px",
                            background: "#fff",
                            justifyContent: "space-between",
                            padding: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {getFilePreview(file)}
                            <span style={{ marginRight: "10px" }}>
                              {file.name}
                            </span>
                          </div>
                          {!uploading && (
                            <Button
                              type="link"
                              danger
                              icon={<CloseCircleOutlined />}
                              onClick={() => removeFile(index)}
                            />
                          )}
                          {uploading && (
                            <Progress
                              type="circle"
                              percent={uploadProgressMap[file.name] || 0}
                              size={20}
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <div>
                        {" "}
                        <img
                          src={images[currentImageIndex]}
                          alt={images[currentImageIndex]}
                          style={{
                            width: isMobileView ? "100%" : "50%",
                            height: "auto",
                            maxHeight: isMobileView ? "16rem" : "18rem",
                            objectFit: "cover",
                            // marginRight: "10px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <Typography.Title
                  level={3}
                  style={{ margin: 0, color: "#AA1945", padding: "1rem" }}
                >
                  Share Your Memories from Our Special Day!
                </Typography.Title>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UploadToS3WithQR;
